/*
? Let's import the global slice and the thunk type
*/

import { AlertColor, AlertPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ObjectId } from "mongodb";
import { output_type_of_database_query } from "../lib/data/search-filters/helpers/buildDatabaseQueries";
import StateInterface from "./state-interface";
import { AppThunk, globalSlice } from "./store";
import { PropertyBoostInterface } from "./sub-interfaces/boost-interface";
import { BeegruUserSubscriptionInformation } from "./sub-interfaces/redux-session-interface";
import { SearchFilterId, SearchFilterOption } from "./sub-interfaces/search-types";
import {
	BoostInterface,
	BoostLocation,
	profile_contextInterface,
	SearchFilterMetrices,
	service_images,
} from "./sub-interfaces/sub-interfaces";

/*
& Now let's describe a thunk for each reducer in the redux-magic/reducers.ts file.
*/

export const changeMeThunk =
	(payload: string): AppThunk =>
	async (dispatch) => {
		/*
    & Let's simulate a long-running task like a network request by awaiting a promise which resolves inside a setTimeout.
    */

		await new Promise<void>((resolve) => {
			const timeout = setTimeout(() => {
				clearTimeout(timeout);
				resolve();
			}, 3000);
		});

		/*
    & Now, we'll dispatch the action to change the state.
    */

		dispatch(globalSlice.actions.changeMe(payload));
	};

export const fetchSinglePropertyThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + slug, {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const setThemeThunk =
	(theme: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setThemeReducer(theme));
	};

export const CreateNewPostThunk =
	(payload: {
		_id: string | ObjectId;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		body: string;
		file_id?: Array<ObjectId | null>;
		privacy_status: string;
		search_string: string;
		search_points: number;
		video?: boolean;
		is_blasted: boolean;
		blasted_post: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewPost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/create-new-post", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CreateNewCommentsThunk =
	(payload: {
		post_id: string | ObjectId;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		name: string;
		url: string;
		tabValue: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewComments({
				tabValue: payload.tabValue,
				response: await await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/comments/add-comment", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};

export const setImageCategoryThunk =
	(payload: StateInterface["category"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setImageCategory(payload));
	};
// export const VerifyEmailThunk =
//   (payload: StateInterface["verify_email"]): AppThunk =>
//   async (dispatch) => {
//     dispatch(globalSlice.actions.VerifyEmail(payload));
//   };

export const setLanguageThunk =
	(payload: StateInterface["default_language"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setLanguage(payload));
	};

export const setImagesIndexThunk =
	(payload: StateInterface["images_index"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setImagesIndex(payload));
	};

export const KycProfileTypesThunk =
	(payload: StateInterface["kyc_types"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.KycProfileTypes(payload));
	};

export const setTabValueThunk =
	(payload: StateInterface["tabValue"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setTabValue(payload));
	};
export const setSaveTabValueThunk =
	(payload: StateInterface["saved_tabValue"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setSaveTabValue(payload));
	};
export const setListingTabValueThunk =
	(payload: StateInterface["listing_tabValue"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setListingTabValue(payload));
	};
export const setAnalyticsTabValueThunk =
	(payload: StateInterface["analytics_tabValue"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setAnalyticsTabValue(payload));
	};
export const setUserLocationThunk =
	(payload: StateInterface["user_location"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setUserLocation(payload));
	};

export const fetchSingleBusinessProfileThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleBusinessProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/" + slug, {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchGlobalSearchResultsThunk =
	(payload: StateInterface["search_query"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchGlobalSearchResults(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "search", {
						method: "POST",
						headers: {
							cache: "no-store",
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreGlobalSearchResultsThunk =
	(payload: StateInterface["search_query"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreGlobalSearchResults(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "search", {
						method: "POST",
						headers: {
							cache: "no-store",
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreGlobalSearchPostsThunk =
	(payload: StateInterface["search_query"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreGlobalSearchPosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "search", {
						method: "POST",
						headers: {
							cache: "no-store",
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setIsSearchingThunk =
	(payload: StateInterface["is_searching"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setIsSearching(payload));
	};

export const setSearchResultsPageNumberThunk =
	(payload: StateInterface["search_results_page_number"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setSearchResultsPageNumber(payload));
	};

export const setIsLoadingThunk =
	(payload: StateInterface["is_loading"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setIsLoading(payload));
	};

export const CreateNewReviewThunk =
	(payload: {
		source: string;
		payload: {
			sessionId: string | ObjectId;
			reviewed_entity_type: string;
			reviewed_entity_id: string | ObjectId;
			location_title: string;
			location_coordinates: Array<number>;
			rating: number;
			body: string;
			creator_is_business_profile: boolean;
			creator_user_id: string | ObjectId;
			creator_business_profile_id: string | ObjectId;
			url: string;
		};
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewReview({
				source: payload.source,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "reviews/create-new-review", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload.payload),
					})
				).json(),
			}),
		);
	};

export const fetchPropertyReviewsPageThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchPropertyReviewsPageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + slug + "/reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const SetintroThunk =
	(payload: StateInterface["intro"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.Setintro(payload));
	};

export const StoreLeadsThunk =
	(payload: {
		name: string;
		phone: {
			country_code: string;
			phone_number: string;
		};
		agreed_to_tc: boolean;
		source: string;
		listing_slug: string | undefined;
		listing_type: string;
		is_guest: boolean;
		listing_title: string | undefined;
		listing_owner: {
			is_business_profile: boolean | undefined;
			user_id: string | ObjectId | undefined;
			business_profile_id: string | ObjectId | undefined;
		};
		user_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.storeleads(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/store-cta-lead", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSingleUserProfileThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/" + slug, {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchBoostPostPageThunk =
	(payload: StateInterface["boost_post_page"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBoostPostPageData(
				await (await fetch(process.env.PRODUCTION_API_URL + "boosting/boost-post-page-data?post_id=" + payload)).json(),
			),
		);
	};

export const fetchBoostUserProfilePageThunk =
	(payload: StateInterface["boost_user_profile_page"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBoostUserProfilePageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "boosting/boost-user-profile-page?user_id=" + payload)
				).json(),
			),
		);
	};
export const fetchBoostBusinessProfilePageThunk =
	(payload: StateInterface["boost_business_profile_page"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBoostBusinessProfilePageData(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "boosting/boost-business-profile-page?business_profile_id=" + payload,
					)
				).json(),
			),
		);
	};

export const fetchBoostPropertyPageThunk =
	(payload: StateInterface["boost_property_page"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBoostPropertyPageData(
				await (await fetch(process.env.PRODUCTION_API_URL + "boosting/boost-property-page?slug=" + payload)).json(),
			),
		);
	};

export const fetchSingleServicePageThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServicePageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug, {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchNeighbourhoodReviewsPageThunk =
	(payload: { location?: string; coords?: string; sessionId: any }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchNeighbourhoodReviewsPageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "neighbourhood-reviews", {
						method: "POST",
						headers: {
							cache: "no-store",
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchServiceReviewsPageThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchServiceReviewsPageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchBoostServicePageThunk =
	(payload: StateInterface["boost_service_page"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBoostServicePageData(
				await (await fetch(process.env.PRODUCTION_API_URL + "boosting/boost-service-page?slug=" + payload)).json(),
			),
		);
	};
export const fetchHomePageThunk =
	({ sessionId, looking_for }: { sessionId: string; looking_for?: any }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchHomePageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "home", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId, looking_for, location }),
					})
				).json(),
			),
		);
	};

export const fetchNotificationPageThunk =
	(payload: {
		sessionId: string;
		profile_context_is_business_profile: boolean;
		profile_context_user_id: string | ObjectId;
		profile_context_business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchNotificationPage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "notifications/fetch-notifications", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreNotificationPageThunk =
	(payload: {
		sessionId: string;
		profile_context_is_business_profile: boolean;
		profile_context_user_id: string | ObjectId;
		profile_context_business_profile_id: string | ObjectId;
		skip: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreNotificationPage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "notifications/load-more-notifications", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchRealtimeNotificationThunk =
	(payload: {
		sessionId: string;
		notification_id: ObjectId | string;
		profile_context_is_business_profile: boolean;
		profile_context_user_id: string | ObjectId;
		profile_context_business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchRealtimeNotification(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "notifications/fetch-realtime-notification", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateNotificationViewedThunk =
	(payload: {
		notification_id: ObjectId | string;
		profile_context_is_business_profile: boolean;
		profile_context_user_id: string | ObjectId;
		profile_context_business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateNotificationViewed(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "notifications/update-notification-viewed", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchFaqsThunk = (): AppThunk => async (dispatch) => {
	dispatch(globalSlice.actions.fetchFaqs(await (await fetch(process.env.PRODUCTION_API_URL + "faqs")).json()));
};

export const setFaqSearchResultsThunk =
	(payload: Array<StateInterface["faq"]>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setFaqSearchResults(payload));
	};

export const fetchSingleFaqThunk =
	({ slug }: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleFaq(await (await fetch(process.env.PRODUCTION_API_URL + "faqs/" + slug)).json()),
		);
	};

export const FaqAddUpvoteThunk =
	(payload: {
		faq_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.FaqAddUpvote(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "faqs/add-upvote", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const FaqAddDownvoteThunk =
	(payload: {
		faq_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.FaqAddDownvote(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "faqs/add-downvote", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const FaqRemoveUpvoteThunk =
	(payload: {
		faq_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.FaqRemoveUpvote(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "faqs/remove-upvote", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const FaqRemoveDownvoteThunk =
	(payload: {
		faq_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.FaqRemoveDownvote(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "faqs/remove-downvote", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const addAdminUserDetailsThunk =
	(payload: { business_profile_id: string; editor_user_id: string; access_level: string; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addAdminUserDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/add-admin", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteAdminUserDetailsThunk =
	(payload: { business_profile_id: string; editor_user_id: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteAdminUserDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/remove-admin", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessAboutThunk =
	(payload: {
		_id: string | ObjectId;
		description: string;
		phone_number: string;
		country_code: string;
		email: string;
		year_established: string | number | Date;
		website_url: string;
		office_address: string | Array<{}>;
		industry_type: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessAbout(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-about", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessBrokerageThunk =
	(payload: { _id: string; agent_brokerage: Array<{}>; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessBrokerage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-brokerage", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessExpertiseThunk =
	(payload: { _id: string; expertise: Array<{}> | []; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessExpertise(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-expertise", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessLanguagesThunk =
	(payload: { _id: string; language: Array<{ language: string; proficiency: string }>; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessLanguages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-languages", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessProfileShortDescriptionThunk =
	(description: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfileShortDescription(description));
	};

export const UpdateBusinessProfilecountry_codeThunk =
	(country_code: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfilecountry_code(country_code));
	};

export const UpdateBusinessProfilePhoneThunk =
	(phone: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfilePhone(phone));
	};

export const UpdateBusinessProfileEmailThunk =
	(email: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfileEmail(email));
	};

export const UpdateBusinessProfileEstDateThunk =
	(EstDate: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfileEstDate(EstDate));
	};

export const UpdateBusinessProfileWebsiteThunk =
	(Website: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfileWebsite(Website));
	};

export const UpdateBusinessProfileOfficeAddressesThunk =
	(OfficeAddresses: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfileOfficeAddresses(OfficeAddresses));
	};

export const UpdateBusinessProfileIndustrytypeThunk =
	(Industrytype: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.UpdateBusinessProfileIndustrytype(Industrytype));
	};

export const UpdateBusinessServicesThunk =
	(payload: { _id: string | ObjectId; services: Array<string> | []; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-services", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessWhyChooseUsThunk =
	(payload: { _id: string; why_choose_us: Array<string> | []; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessWhyChooseUs(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-why-choose-us", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessFAQsThunk =
	(payload: { _id: string; faqs: Array<{ question: string; answer: string }>; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessFAQs(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-faqs", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessHiringThunk =
	(payload: { _id: string; hiring: Array<{}>; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessHiring(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-hiring", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddSepcialOfferDetailsForBusinessProfileThunk =
	(payload: {
		_id: string;
		title: string;
		description: string;
		media_url: string;
		mime_type: string;
		from_date: string;
		to_date: string;
		eligibility_criteria: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddSepcialOfferDetailsForBusinessProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/add-special-offers", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessHonorsThunk =
	(payload: { _id: string; honors: Array<{}>; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessHonors(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-honor-awards", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessLicencesThunk =
	(payload: { _id: string; licences: Array<{}>; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessLicences(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-licenses-certifications", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateUserAboutDetailsThunk =
	(payload: {
		_id: string | ObjectId;
		description: string;
		firstname: string;
		lastname: string;
		// phone_number: string;
		// country_code: string;
		email: string;
		date_of_birth: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateUserAboutDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/update-about-details", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateUserSkillsThunk =
	(payload: { _id: string; skills: Array<string> }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateUserSkills(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/update-skills", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddUserCoursesThunk =
	(payload: { _id: string; courses: Array<string> }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddUserCourses(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/add-courses", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddUserLanguageThunk =
	(payload: { _id: string; language: Array<string> }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddUserLanguage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/add-languages", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddExperienceForUserThunk =
	(payload: {
		_id: string;
		designation: string;
		joined_at: string;
		organization: string;
		left_at: string;
		request_status: string;
		request_date: string;
		accepted_or_rejected_by: string;
		accepted_or_rejected_date: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addExperienceForUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/add-experience", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddHonorsAndAwardsDetailsForUserThunk =
	(payload: {
		_id: string;
		title: string;
		description: string;
		issued_by: string;
		issued_at: string;
		media_url: string;
		mime_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddHonorsAndAwardsDetailsForUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/add-honor-awards", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddLicenseAndCertificationDetailsForUserThunk =
	(payload: {
		_id: string;
		title: string;
		description: string;
		issued_by: string;
		issued_at: string;
		media_url: string;
		mime_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddLicenseAndCertificationDetailsForUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/add-licenses-certifications", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const addSaveEntityBusinessProfileThunk =
	(payload: {
		is_business_profile: boolean;
		user_id: ObjectId | string;
		business_profile_id: ObjectId | string;
		entity_id: ObjectId | string;
		entity_type: string;
		add_remove: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddSaveEntityBusinessProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/add-saved-entity", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const addSaveEntityUserProfileThunk =
	(payload: { user_id: string; entity_id: string | ObjectId; entity_type: string; add_remove: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddSaveEntityUserProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/add-saved-entity", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateServiceAreasOfOperationThunk =
	(payload: {
		service_id: ObjectId | string;
		areas_of_operation:
			| Array<{
					title: string;
					pincode: string;
					location_coordinates: Array<number>;
			  }>
			| [];
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateServiceAreasOfOperation(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/services/edit-services/update-areas-of-operation", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateServiceOverViewThunk =
	(payload: {
		service_id: ObjectId | string;
		overview: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateServiceOverView(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/services/edit-services/update-service-overview", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateServiceDetailsThunk =
	(payload: {
		service_id: ObjectId | string;
		// delimited_search_string: string;
		// search_string: string;
		service_category: string;
		service_type: Array<string>;
		service_subtype: Array<string>;
		other_service_subtype: string;
		tags: Array<string>;
		min_price: number;
		max_price: number;
		currency: string;
		show_price: boolean;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateServiceDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/services/edit-services/update-service-details", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateServiceSpecificationThunk =
	(payload: {
		service_id: ObjectId | string;
		service_specifications: Array<string>;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateServiceSpecification(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/services/edit-services/update-service-specifications", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateServiceProviderInfoThunk =
	(payload: {
		service_id: ObjectId | string;
		service_provider_name: string;
		service_provider_year_established: number;
		service_provider_description: string;
		service_provider_upcoming_projects: number;
		service_provider_completed_projects: number;
		service_provider_ongoing_projects: number;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateServiceProvider(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/services/edit-services/update-service-provider-info", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const followUserThunk =
	(payload: {
		follower_is_business_profile: boolean;
		follower_user_id: ObjectId | string;
		follower_business_profile_id: ObjectId | string;
		following_is_business_profile: boolean;
		following_user_id: ObjectId | string;
		following_business_profile_id: ObjectId | string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.followUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/follow-user", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const unfollowUserThunk =
	(payload: {
		follower_is_business_profile: boolean;
		follower_user_id: ObjectId | string;
		follower_business_profile_id: ObjectId | string;
		following_is_business_profile: boolean;
		following_user_id: ObjectId | string;
		following_business_profile_id: ObjectId | string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.unfollowUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/unfollow-user", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdatePropertyDetailsThunk =
	(payload: {
		sessionId: string | ObjectId;
		property_id: string | ObjectId;
		search_string: string;
		delimited_search_string: string;
		transaction_type: string;
		property_category: string;
		property_type: Array<string>;
		property_subtype: string;
		min_price: string | number;
		max_price: string | number;
		security_deposit: number;
		possession_date: Date | string;
		property_age: number;
		availability: Array<string>;
		unit_facing: Array<string>;
		land_facing: Array<string>;
		tag: Array<string>;
		furnishing: Array<string> | [];
		min_bedrooms: string | number;
		max_bedrooms: string | number;
		min_bathrooms: string | number;
		max_bathrooms: string | number;
		min_balconies: string | number;
		max_balconies: string | number;
		total_floors: string | number;
		floor_no: string | number;
		no_of_seats: string | number;
		no_of_units: string | number;
		land_area: number;
		land_area_unit: string;
		min_super_built_up_area: string | number;
		max_super_built_up_area: string | number;
		super_built_up_area_unit: string;
		min_carpet_area: string | number;
		max_carpet_area: string | number;
		carpet_area_unit: string;
		plot_dimensions: Array<string> | string;
		other_plot_dimensions: string[];
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdatePropertyDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-property-details", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateConfigAndAreaThunk =
	(payload: {
		sessionId: string | ObjectId;
		property_id: string | ObjectId;
		search_string: string;
		delimited_search_string: string;
		min_price: string | number;
		max_price: string | number;
		land_area: number;
		land_area_unit: string;
		min_super_built_up_area: string | number;
		max_super_built_up_area: string | number;
		super_built_up_area_unit: string;
		min_carpet_area: string | number;
		max_carpet_area: string | number;
		carpet_area_unit: string;
		plot_dimensions: Array<string> | string;
		other_plot_dimensions: string[];
		property_type: Array<string>;
		furnishing: Array<string>;
		min_bedrooms: string | number;
		max_bedrooms: string | number;
		min_bathrooms: string | number;
		max_bathrooms: string | number;
		min_balconies: string | number;
		max_balconies: string | number;
		total_floors: string | number;
		floor_no: string | number;
		no_of_seats: string | number;
		no_of_units: string | number;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateConfigAndArea(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-config-and-area", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateAboutDeveloperThunk =
	(payload: {
		developer_name: string;
		description: string;
		year_est: number;
		// upcoming_projects: string;
		// completed_projects: string;
		// ongoing_projects: string;
		phoneNumber: string;
		countryCode: string;
		delimited_search_string: string;
		search_string: string;
		property_id: string | ObjectId;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateAboutDeveloperProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-about-developer", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const UpdateAboutLocationPropertyThunk =
	(payload: {
		about_location: Array<string>;
		property_id: string | ObjectId;
		editor_is_business_profile: boolean;
		phoneNumber: string;
		countryCode: string;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateAboutLocationProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-about-location", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const UpdateAboutPropertyThunk =
	(payload: {
		about_property: Array<string>;
		property_id: string | ObjectId;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateAboutProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-about-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateAmenitiesThunk =
	(payload: {
		amenities: Array<{ id: string; label: string; icon: string }> | [];
		property_id: string | ObjectId;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateAmenities(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-amenities", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateOverviewThunk =
	(payload: {
		overview: string;
		delimited_search_string: string;
		search_string: string;
		property_id: ObjectId | string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateOverView(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-overview", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateAiOverviewThunk =
	(payload: {
		overview: string;
		delimited_search_string: string;
		search_string: string;
		property_id: ObjectId | string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		manual_overview: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateAiOverView(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-ai-overview", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateVicinityThunk =
	(payload: {
		property_id: string | ObjectId;
		vicinity: Array<{}>;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateVicinity(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-vicinity", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setDrawerScreenThunk =
	(payload: string): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setDrawerScreen(payload));
	};

export const setCreateBoostThunk =
	(payload: StateInterface["boost"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setCreateBoost(payload));
	};

export const setStartBoostThunk =
	(payload: {
		boosted_entity_type: string;
		boosted_entity_id: string;
		boosted_by_is_business_profile: boolean;
		boosted_by_business_profile_id: string;
		boosted_by_user_id: string;
		boost_location: Array<{}>;
		boost_start_date: [Date | string, Date | string];
		boost_end_date: [Date | string, Date | string];
		beegru_points_cost: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.setStartBoost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/boosting/create-new-boost", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const EmployeeRequestThunk =
	(payload: {
		employee_is_business_profile: boolean;
		employee_business_profile_id: string;
		employee_user_id: string;
		business_profile_id: ObjectId | string;
		editor_user_id: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.EmployeeRequest(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/new-employee-request", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const acceptEmployeeRequestThunk =
	(payload: { employee_request_id: string; business_profile_id: string; editor_user_id: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.acceptEmployeeRequest(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/accept-employee-request", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const deleteEmployeeRequestThunk =
	(payload: { employee_user_id: string; business_profile_id: string; editor_user_id: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteEmployeeRequest(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/remove-employee", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateBoostThunk =
	(payload: {
		boosted_entity_type: string;
		boosted_entity_id: string;
		boosted_by_is_business_profile: boolean;
		boosted_by_business_profile_id: string;
		boosted_by_user_id: string;
		boost_location: Array<{}>;
		boost_start_date: [Date | string, Date | string];
		boost_end_date: [Date | string, Date | string];
		beegru_points_cost: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateBoost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/boosting/update-boost", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const cancelBoostThunk =
	(payload: {
		boosted_entity_type: string;
		boosted_entity_id: string;
		boosted_by_is_business_profile: boolean;
		boosted_by_business_profile_id: string;
		boosted_by_user_id: string;
		boost_location: Array<{}>;
		boost_start_date: [Date | string, Date | string];
		boost_end_date: [Date | string, Date | string];
		beegru_points_cost: number;
		boost_id: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.cancelBoost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/boosting/cancel-boost", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const SetDefaultLookingForThunk =
	(payload: StateInterface["looking_for"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.SetDefaultLookingFor(payload));
	};

export const SetIsStudioThunk =
	(payload: StateInterface["is_studio"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.SetIsStudio(payload));
	};

export const SetDefaultLocationBoostThunk =
	(payload: StateInterface["location_boost"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.SetDefaultLocationBoost(payload));
	};

export const SetDefaultLocationThunk =
	(payload: StateInterface["location"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.SetDefaultLocation(payload));
	};

export const fetchReceiptPageThunk =
	(payload: StateInterface["receipt_page"]): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchReceiptPageData(
				await (await fetch(process.env.PRODUCTION_API_URL + "receipt-data/order_" + payload)).json(),
			),
		);
	};

export const fetchAccountSettingsPageThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsPageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/" + slug, {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchAccountSettingsProfileKycInfoThunk =
	(payload: { profile_to_verify_id: ObjectId | string; profile_to_verify_type: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsProfileKycInfo(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-profile-kyc-info", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const addPropertyImagesThunk =
	(payload: {
		property_id: ObjectId | string;
		file_id: Array<ObjectId | null>;
		priorityIndex: number;
		isPrioritySelected: boolean;
		category: Array<string>;
		mime_type: Array<string>;
		sessionId: string;
		phoneNumber: string;
		countryCode: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addPropertyImages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/upload-property-images", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CreateNewPropertyThunk =
	(payload: {
		slug: string;
		title: string;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		// new_property_data: StateInterface["new_property_data"];
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/create-new-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const createNewPropertyWithUploadToolThunk =
	(payload: {
		createNewBusinessProfile: Boolean;
		businessProfileId: string | ObjectId;
		creatorIsBusinessProfile: boolean;
		businessProfileName: string;
		businessProfileType: string;
		userId: string | ObjectId;
		phoneNumber: string;
		countryCode: string;
		propertyName: string;
		propertyLocationName: string;
		propertyLocationCoordinates: Array<number>;
		propertyTransactionType: string;
		propertyCategory: string;
		propertyType: Array<string>;
		propertySubtype: string;
		propertyMinPrice: number;
		propertyMaxPrice: number;
		propertySecurityDeposit: number;
		propertyStatus: string;
		propertyPossessionDate: string | Date;
		propertyAge: number;
		propertyFacing: Array<string>;
		propertyFurnishing: Array<string>;
		propertyMinBedrooms: number;
		propertyMaxBedrooms: number;
		propertyMinBathrooms: number;
		propertyMaxBathroom: number;
		propertyNoOfBalconies: number;
		propertyNoOfSeats: number;
		propertyNoOfUnits: number;
		propertyNoOfFloors: number;
		propertyPlotDimensions: Array<string>;
		propertyLandAreaUnit: string;
		propertyLandArea: number;
		propertySuperBuiltUpAreaUnit: string;
		propertyMinSuperBuiltUpArea: number;
		propertyMaxSuperBuiltUpArea: number;
		propertyIsCarpetAreaPercentage: true;
		propertyCarpetAreaPercentage: string;
		propertyCarpetAreaUnit: string;
		propertyMinCarpetArea: number;
		propertyMaxCarpetArea: number;
		propertyImages: Array<{
			file_id: ObjectId | string;
			mime_type: string;
			category: string;
			caption: string;
			priority: number;
		}>;
		propertyOverview: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewPropertyWithUploadTool(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/edit-property/create-new-property-with-upload-tools",
						{
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const setPostPropertyWithUploadToolSnackbarErrorThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setPostPropertyWithUploadToolSnackbarError(payload));
	};

export const CreateNewServiceThunk =
	(payload: {
		slug: string;
		title: string;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewService(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/create-new-service", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CreateNewServiceWithUploadToolThunk =
	(payload: {
		createNewBusinessProfile: Boolean;
		businessProfileId: string | ObjectId;
		businessProfileName: string;
		businessProfileType: string;
		userId: string | ObjectId;
		phoneNumber: string;
		countryCode: string;
		serviceName: string;
		serviceLocationName: string;
		serviceLocationCoordinates: Array<number>;
		servicProviderName: string;
		serviceType: Array<string>;
		serviceSubType: Array<string>;
		serviceMinPrice: number;
		serviceMaxPrice: number;
		serviceImages: service_images[];
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewServiceWithUploadTool(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/create-new-service-with-upload-tools", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdatePropertyImageThunk =
	(payload: {
		property_id: ObjectId | string;
		file_id: Array<ObjectId | string>;
		prev_id: Array<ObjectId | string>;
		mime_type: Array<string | undefined>;
		phoneNumber: string;
		countryCode: string;
		caption: string;
		category: Array<string>;
		priority: number;
		sessionId: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updatePropertyImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/update-images", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CropPropertyImageThunk =
	(payload: {
		property_id: ObjectId | string;
		file_id: ObjectId | string;
		prev_id: ObjectId | string;
		mime_type: string | undefined;
		sessionId: string;
		phoneNumber: string;
		countryCode: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.cropPropertyImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/crop-image", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deletePropertyImageThunk =
	(payload: {
		property_id: ObjectId | string;
		file_id: ObjectId | string;
		sessionId: string;
		phoneNumber: string;
		countryCode: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deletePropertyImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/delete-property-image", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteServiceImageThunk =
	(payload: {
		service_id: ObjectId | string;
		file_id: ObjectId | string;
		sessionId: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteServiceImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/delete-service-image", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteBusinessProfilePictureThunk =
	(payload: {
		business_profile_id: ObjectId | string;
		file_id: ObjectId | string;
		sessionId: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteBusinessProfilePicture(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/delete-profile-picture", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteBusinessCoverImageThunk =
	(payload: {
		business_profile_id: ObjectId | string;
		file_id: ObjectId | string;
		sessionId: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteBusinessCoverImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/delete-cover-image", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteUserProfilePictureThunk =
	(payload: { user_id: ObjectId | string; file_id: ObjectId | string; sessionId: string; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteUserProfilePicture(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/delete-profile-picture", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteUserCoverImageThunk =
	(payload: { user_id: ObjectId | string; file_id: ObjectId | string; sessionId: string; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteUserCoverImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/delete-cover-image", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CreateNewSubscriptionThunk =
	(payload: {
		plan_slug: string;
		subscribed_user_id: string | ObjectId;
		subscribed_entity_id: string;
		subscribed_entity_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewSubscription(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "subscriptions/create-new-subscription", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const RenewSubscriptionThunk =
	(payload: {
		plan_slug: string;
		subscription_id: ObjectId | string;
		subscribed_user_id: string | ObjectId;
		subscribed_entity_id: string | ObjectId;
		subscribed_entity_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.RenewSubscription(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "subscriptions/renew-subscription", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CancelSubscriptionThunk =
	(payload: {
		subscription_id: ObjectId | string;
		subscribed_user_id: string | ObjectId;
		subscribed_entity_id: string | ObjectId;
		subscribed_entity_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CancelSubscription(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "subscriptions/cancel-subscription", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const ProfileToUpgradeThunk =
	(payload: StateInterface["profile_to_upgrade"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.ProfileToUpgrade(payload));
	};

export const ProfileToUpgradeIdThunk =
	(payload: StateInterface["profile_to_upgrade_id"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.ProfileToUpgradeId(payload));
	};

export const ProfileToUpgradeTypeThunk =
	(payload: StateInterface["profile_to_upgrade_type"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.ProfileToUpgradeType(payload));
	};

export const deletePropertyDetailsThunk =
	(payload: {
		property_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		sessionId: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deletePropertyDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/delete-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const addServiceImagesThunk =
	(payload: {
		service_id: ObjectId | string;
		file_id: Array<ObjectId | null>;
		priorityIndex: number;
		isPrioritySelected: boolean;
		mime_type: Array<string>;
		sessionId: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addServiceImages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/upload-service-images", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateServiceImageThunk =
	(payload: {
		service_id: ObjectId | string;
		file_id: Object | null;
		prev_id: any;
		mime_type: string | undefined;
		caption: string;
		priority: number;
		sessionId: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateServiceImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/update-images", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CropServiceImageThunk =
	(payload: {
		service_id: ObjectId | string;
		file_id: ObjectId | string;
		prev_id: ObjectId | string;
		mime_type: string | undefined;
		sessionId: string;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.cropServiceImage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/crop-image", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updatePersonalInfoThunk =
	(payload: {
		user_id: string | ObjectId;
		first_name: string;
		last_name: string;
		company_name: string;
		company_slug: string;
		occupation: string;
		email: string;
		address_line1: string;
		address_line2: string;
		city: string;
		state: string;
		country: string;
		pincode: string;
		profile_picture_file_id: ObjectId | string;
		sessionId: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updatePersonalInfo(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/update-personal-info", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateUserKycInfoThunk =
	(payload: {
		sessionId: string;
		profile_id: ObjectId | string;
		profile_type: string;
		kyc_type: string;
		pan_number: string;
		selected_document: string;
		pan_frontimage_file_id: ObjectId | string | null;
		aadhaar_number: string;
		aadhaar_frontimage_file_id: ObjectId | string | null;
		aadhaar_backimage_file_id: ObjectId | string | null;
		voter_id_number: string;
		voter_id_frontimage_file_id: ObjectId | string | null;
		voter_id_backimage_file_id: ObjectId | string | null;
		driving_licence_number: string;
		driving_licence_frontimage_file_id: ObjectId | string | null;
		driving_licence_backimage_file_id: ObjectId | string | null;
		passport_number: string;
		passport_frontimage_file_id: ObjectId | string | null;
		passport_backimage_file_id: ObjectId | string | null;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateUserKycInfo(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/update-user-kyc-info", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateBusinessProfileKycInfoThunk =
	(payload: {
		sessionId: string;
		profile_id: ObjectId | string;
		profile_type: string;
		kyc_type: string;
		pan_number: string;
		selected_document: string;
		pan_frontimage_file_id: ObjectId | string | null;
		aadhaar_number: string;
		aadhaar_frontimage_file_id: ObjectId | string | null;
		aadhaar_backimage_file_id: ObjectId | string | null;
		voter_id_number: string;
		voter_id_frontimage_file_id: ObjectId | string | null;
		voter_id_backimage_file_id: ObjectId | string | null;
		driving_licence_number: string;
		driving_licence_frontimage_file_id: ObjectId | string | null;
		driving_licence_backimage_file_id: ObjectId | string | null;
		passport_number: string;
		passport_frontimage_file_id: ObjectId | string | null;
		passport_backimage_file_id: ObjectId | string | null | undefined;
		tin_number: string | undefined;
		tin_certificate_frontimage_file_id: ObjectId | string | null | undefined;
		gst_number: string | undefined;
		gst_certificate_frontimage_file_id: ObjectId | string | null | undefined;
		legal_licence_number: string | undefined;
		legal_licence_frontimage_file_id: ObjectId | string | null | undefined;
		moa_number: string | undefined;
		moa_frontimage_file_id: ObjectId | string | null | undefined;
		rera_number: string | undefined;
		rera_certificate_frontimage_file_id: ObjectId | string | null | undefined;
		partnership_deed_number: string | undefined;
		partnership_deed_frontimage_file_id: ObjectId | string | null | undefined;
		partnership_reg_certificate_number: string | undefined;
		partnership_reg_certificate_frontimage_file_id: ObjectId | string | null | undefined;
		aoa_number: string | undefined;
		aoa_frontimage_file_id: ObjectId | string | null | undefined;
		coi_number: string | undefined;
		coi_frontimage_file_id: ObjectId | string | null | undefined;
		llp_agreement_number: string | undefined;
		llp_agreement_frontimage_file_id: ObjectId | string | null | undefined;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateBusinessProfileKycInfo(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/update-business-profile-kyc-info", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateLocationPropertyThunk =
	(payload: {
		property_id: string | ObjectId;
		location_title: string;
		pincode: string;
		location_coordinates: Array<number>;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateLocationProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/update-location", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateServiceLocationThunk =
	(payload: {
		service_id: string | ObjectId;
		location_title: string;
		pincode: string;
		location_coordinates: Array<number>;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		url: string;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateServiceLocation(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/update-location", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const addProfilePictureThunk =
	(payload: {
		value: string;
		user_id: ObjectId | string;
		profile_picture_file_id: ObjectId | string;
		mime_type: string;
		sessionId: ObjectId | string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addProfilePicture(
				(
					await (
						await fetch(process.env.PRODUCTION_API_URL + "account-settings/add-profile-picture", {
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						})
					).json()
				).payload,
			),
		);
	};

export const addCoverImageThunk =
	(payload: {
		value: string;
		user_id: ObjectId | string;
		cover_image_file_id: ObjectId | string;
		mime_type: string | undefined;
		sessionId: ObjectId | string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addCoverImage(
				(
					await (
						await fetch(process.env.PRODUCTION_API_URL + "account-settings/add-cover-image", {
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						})
					).json()
				).payload,
			),
		);
	};

export const addBusinessProfilePictureThunk =
	(payload: {
		value: string;
		business_profile_id: ObjectId | string;
		profile_picture_file_id: ObjectId | string;
		mime_type: string | undefined;
		sessionId: ObjectId | string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addBusinessProfilePicture(
				(
					await (
						await fetch(process.env.PRODUCTION_API_URL + "business-profiles/add-profile-picture", {
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						})
					).json()
				).payload,
			),
		);
	};

export const addBusinessCoverImageThunk =
	(payload: {
		value: string;
		business_profile_id: ObjectId | string;
		cover_image_file_id: ObjectId | string;
		mime_type: string | undefined;
		sessionId: ObjectId | string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addBusinessProfileCoverImage(
				(
					await (
						await fetch(process.env.PRODUCTION_API_URL + "business-profiles/add-cover-image", {
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						})
					).json()
				).payload,
			),
		);
	};

export const createNewBusinessProfileThunk =
	(payload: {
		slug: string;
		title: string;
		business_profile_type: string;
		creator_user_id: ObjectId | string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewBusinessProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/create-new-business-profile", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deleteBusinessProfileThunk =
	(payload: {
		user_id: ObjectId | string;
		business_profile_id: ObjectId | string;
		sessionId: ObjectId | string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteBusinessProfile(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/delete-business-profile", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const createNewOFBPropertyThunk =
	(payload: {
		slug: string;
		title: string;
		creator_is_business_profile: boolean | undefined;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewOFBProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/create-new-ofb-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deletePropertyOFBDetailsThunk =
	(payload: {
		property_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		sessionId: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deletePropertyOFBDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/delete-ofb-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const deletePropertyOFBUserThunk =
	(payload: {
		property_id: string;
		is_business_profile: boolean;
		user_id: string;
		business_profile_id: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deletePropertyOFBUsers(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/delete-ofb-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const createNewOFBPropertyUserThunk =
	(payload: {
		slug: string;
		title: string;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewOFBPropertyUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/create-new-ofb-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const updateBoomsThunk =
	(payload: {
		post_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		created_at: string;
		url: string;
		tabValue: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateBooms({
				tabValue: payload.tabValue,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/add-booms", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};
export const RemoveBoomsThunk =
	(payload: {
		post_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		created_at: string;
		url: string;
		tabValue: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.RemoveBooms({
				tabValue: payload.tabValue,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/remove-booms", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};
export const updateBlastsThunk =
	(payload: {
		post_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		created_at: string;
		url: string;
		tabValue: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateBlasts({
				tabValue: payload.tabValue,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/add-blasts", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};

export const CreateNewPostUserThunk =
	(payload: {
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		body: string;
		file_id: Array<ObjectId | null>;
		privacy_status: string;
		search_string: string;
		search_points: number;
		is_blasted: boolean;
		blasted_post: ObjectId | string;
		type: string;
		sessionId: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewPostUser({
				type: payload.type,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/create-new-post", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};

export const CreateNewCommentsUserPostThunk =
	(payload: {
		post_id: string | ObjectId;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		name: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewCommentsUserPost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/comments/add-comment", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const deletePostUserThunk =
	(payload: {
		post_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		url: string;
		sessionId: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deletePostUser(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/delete-post", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const ReplyToCommentThunk =
	(payload: {
		body: string;
		deleted: boolean;
		reported: boolean;
		comment_id: string | ObjectId;
		post_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		url: string;
		tabValue: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.ReplyToComment({
				tabValue: payload.tabValue,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/comments/add-comment-reply", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};

export const deleteServiceDetailsThunk =
	(payload: {
		service_id: string | ObjectId;
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		sessionId: string | ObjectId;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteServiceDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/delete-services", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const setProfileContextThunk =
	(payload: {
		is_business_profile: boolean;
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		page_type: string;
		user_kyc_status: string;
		user_subscription_information: BeegruUserSubscriptionInformation;
		remember_choice: boolean;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.setProfileContext(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/profile-context", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const setActionForboomsThunk =
	(payload: StateInterface["actionForBooms"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setActionForbooms(payload));
	};

export const profileContextThunk =
	(payload: StateInterface["profile_context"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.profileContext(payload));
	};
export const bookmarkThunk =
	(payload: {
		user_id: string | ObjectId;
		business_profile_id: string | ObjectId;
		is_business_profile: boolean;
		entity_id: string | ObjectId;
		entity_type: string;
		add_remove: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.bookmark(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/add-remove-bookmark", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const reportPostThunk =
	(payload: { user_id: string | ObjectId; reason: string; post_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.reportPost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/report-post", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const postSwrForUserThunk =
	(payload: StateInterface["postSwrForUser"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.postSwrForUser(payload));
	};
export const postSwrForBusinessThunk =
	(payload: StateInterface["postSwrForBusiness"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.postSwrForBusiness(payload));
	};

export const commentSwrThunk =
	(payload: StateInterface["comment_swr"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.commentSwr(payload));
	};

export const PublishPropertyThunk =
	(payload: {
		property_id: string | ObjectId;
		status: string;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.publishproperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/publish-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const PublicPrivatePropertyThunk =
	(payload: { property_id: string | ObjectId; is_public: boolean }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.publicproperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/public-private-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const PublishServiceThunk =
	(payload: {
		service_id: string | ObjectId;
		status: string;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		phoneNumber: string;
		countryCode: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.publishservice(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/publish-service", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const PublicPrivateServiceThunk =
	(payload: { service_id: string | ObjectId; is_public: boolean }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.publicservice(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-services/public-private-service", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const OnBoardingThunk =
	(payload: { user_id: string | ObjectId; first_name: string; last_name: string; recoveryEmailId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.OnBoarding(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "on-boarding/On-boarding", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateNoOfEmployeesThunk =
	(payload: {
		no_of_employees: number;
		business_profile_id: string | ObjectId;
		url: string;
		creator_user_id: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updateNofEmployees(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-no-of-employees", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const setSavedIsPublicThunk =
	(payload: { business_profile_id: string | ObjectId; saved_is_public: boolean }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.setSavedIsPublic(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/saved_is_public", {
						method: "POST",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePostThunk =
	({ id, sessionId }: { id: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "posts/" + id, {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const businessProfileListTabViewThunk =
	(payload: StateInterface["business_profile_listings_tab_view"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.businessProfileListTabView(payload));
	};

export const setSearchPropertiesThunk =
	(payload: StateInterface["single_business_profile"]["properties_og"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setSearchProperties(payload));
	};
export const setSearchServiceThunk =
	(payload: StateInterface["single_business_profile"]["services_og"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setSearchServices(payload));
	};
export const setSearchOFBThunk =
	(payload: StateInterface["single_business_profile"]["ofb_properties_og"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setSearchOFBProperties(payload));
	};

export const loadMorePropertiesThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-properties", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreServicesThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-services", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreOfbPropertyThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreOfbProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-ofb", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreSavedPropertiesThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreSavedProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-saved-properties", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreSavedOFBThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreSavedOFB(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-saved-ofb", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreUserSavedPropertiesThunk =
	(payload: { skip: number; sessionId: string; user_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreUserSavedProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-saved-properties", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreUserSavedOFBThunk =
	(payload: { skip: number; sessionId: string; user_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreUserSavedOFB(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-saved-properties", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreSavedServicesThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreSavedServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-saved-services", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreUserSavedServicesThunk =
	(payload: { skip: number; sessionId: string; user_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreUserSavedServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-saved-services", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreSavedPostsThunk =
	(payload: { skip: number; sessionId: string; business_profile_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreSavedPosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-saved-posts", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreUserSavedPostsThunk =
	(payload: { skip: number; sessionId: string; user_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreUserSavedPosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-saved-posts", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const searchPropertyListingsThunk =
	(payload: {
		search_term: string;
		sessionId: string;
		business_profile_id: string | ObjectId;
		business_profile_slug: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchPropertyListings(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/search-property-listings", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const changeRecievePromotionalEmailsThunk =
	(payload: {
		sessionId: string | ObjectId;
		user_id: ObjectId | string;
		receive_promotional_emails: boolean;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.changeRecievePromotionalEmails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/update-receive-promotional-emails", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const changeDataTuningThunk =
	(payload: { fine_tuning_check: boolean; property_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.changeDataTuning(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/overview-data-tuning", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const changeAutoplayVideosThunk =
	(payload: {
		sessionId: string | ObjectId;
		user_id: ObjectId | string;
		autoplay_videos: boolean;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.changeAutoplayVideos(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/update-autoplay-videos", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const changeDefaultVideoQualityThunk =
	(payload: {
		sessionId: string | ObjectId;
		user_id: ObjectId | string;
		default_video_quality: string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.changeDefaultVideoQuality(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/update-default-video-quality", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const loadMoreUserPropertiesThunk =
	(payload: { skip: number; sessionId: string; user_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.loadMoreUserProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-properties", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const searchUserPropertyListingsThunk =
	(payload: { search_term: string; sessionId: string; user_id: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchUserPropertyListings(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/search-property-listings", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const CreateNewBugReportThunk =
	(payload: {
		first_name: string;
		last_name: string;
		user_id: string;
		phone: string;
		status: string;
		country_code: string;
		feedback_type: string;
		description: string;
		image_path: string;
		url: string;
		screenshot_url: ArrayBuffer | null;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewBugReport(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "feedback/create-new-feedback", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const CreateWebsiteReportThunk =
	(payload: {
		report_type: string;
		description: string;
		is_business_profile: boolean;
		business_profile_id: ObjectId | string;
		user_id: ObjectId | string;
		url: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewReport(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "website-reports/create-new-feedback", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setHomeLocationThunk =
	(payload: StateInterface["home_location"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setHomeLocation(payload));
	};

const tempIP = "https://beegru.com/api/";

export const fetchHomePageHotPropertiesThunk =
	({
		sessionId,
		looking_for,
		location,
		transaction_type,
	}: {
		sessionId: string;
		looking_for?: any;
		location?: any;
		transaction_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchHomePageHotPropertiesData(
				await (
					await fetch(tempIP + "home/hot-properties", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({
							sessionId,
							looking_for,
							location,
							transaction_type,
						}),
					})
				).json(),
			),
		);
	};

export const fetchHomePageHotPropertiesBuyLocalStorageThunk =
	(properties: Array<any>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.fetchHomePageHotPropertiesBuyLocalStorageData(properties));
	};

export const fetchHomePageHotPropertiesRentLocalStorageThunk =
	(properties: Array<any>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.fetchHomePageHotPropertiesRentLocalStorageData(properties));
	};

export const fetchHomePageHotPropertiesOfbLocalStorageThunk =
	(properties: Array<any>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.fetchHomePageHotPropertiesOfbLocalStorageData(properties));
	};

export const fetchHomePageFeaturedPropertiesThunk =
	({
		sessionId,
		looking_for,
		location,
		transaction_type,
	}: {
		sessionId: string;
		looking_for?: any;
		location?: any;
		transaction_type: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchHomePageFeaturedPropertiesData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "home/featured-properties", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({
							sessionId,
							looking_for,
							location,
							transaction_type,
						}),
					})
				).json(),
			),
		);
	};

export const fetchHomePageTopServicesThunk =
	({ sessionId, looking_for, location }: { sessionId: string; looking_for?: any; location?: any }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchHomePageTopServicesData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "home/top-services", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId, looking_for, location }),
					})
				).json(),
			),
		);
	};
// export const fetchHomePageTrendingServicesThunk =
// 	({ sessionId, looking_for, location }: { sessionId: string; looking_for?: any; location?: any }): AppThunk =>
// 	async (dispatch) => {
// 		dispatch(
// 			globalSlice.actions.fetchHomePageTrendingServicesData(
// 				await (
// 					await fetch(process.env.PRODUCTION_API_URL + "home/trending-services", {
// 						method: "POST",
// 						headers: {
// 							"Content-Type": "text/plain",
// 						},
// 						body: JSON.stringify({ sessionId, looking_for, location }),
// 					})
// 				).json(),
// 			),
// 		);
// 	};

export const fetchHomePageTopUsersThunk =
	({ sessionId, looking_for, location }: { sessionId: string; looking_for?: any; location?: any }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchHomePageTopUsersData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "home/top-users", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId, looking_for, location }),
					})
				).json(),
			),
		);
	};
// export const fetchHomePageTrendingUsersThunk =
// 	({ sessionId, looking_for, location }: { sessionId: string; looking_for?: any; location?: any }): AppThunk =>
// 	async (dispatch) => {
// 		dispatch(
// 			globalSlice.actions.fetchHomePageTrendingUsersData(
// 				await (
// 					await fetch(process.env.PRODUCTION_API_URL + "home/trending-users", {
// 						method: "POST",
// 						headers: {
// 							"Content-Type": "text/plain",
// 						},
// 						body: JSON.stringify({ sessionId, looking_for, location }),
// 					})
// 				).json(),
// 			),
// 		);
// 	};
export const fetchHomePageTopBusinessProfilesThunk =
	({ sessionId, looking_for, location }: { sessionId: string; looking_for?: any; location?: any }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchHomePageTopBusinessProfilesData(
				await (
					await fetch(tempIP + "home/top-business-profile", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId, looking_for, location }),
					})
				).json(),
			),
		);
	};
// export const fetchHomePageTrendingBusinessProfilesThunk =
// 	({ sessionId, looking_for, location }: { sessionId: string; looking_for?: any; location?: any }): AppThunk =>
// 	async (dispatch) => {
// 		dispatch(
// 			globalSlice.actions.fetchHomePageTrendingBusinessProfilesData(
// 				await (
// 					await fetch(process.env.PRODUCTION_API_URL + "home/trending-business-profile", {
// 						method: "POST",
// 						headers: {
// 							"Content-Type": "text/plain",
// 						},
// 						body: JSON.stringify({ sessionId, looking_for, location }),
// 					})
// 				).json(),
// 			),
// 		);
// 	};
export const setHomePageBusinessProfileLoadingThunk =
	(loading: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setHomePageBusinessProfileLoading(loading));
	};
export const fetchSingleUserProfileCoverImageAndProfilePictureThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfileCoverImageAndProfilePicture(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-cover-image-and-profile-image", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchSingleUserProfileFollowersAndFollowingsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfileFollowersAndFollowings(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-followers-and-followings", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchSingleUserProfileAboutDetailsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfileAboutDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-about-details", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSingleUserProfilePostsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfilePosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-user-posts", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const LoadingMoreUserProfilePostsThunk =
	(payload: { user_id: string | ObjectId; sessionId: string; pageNumber: string; pageSize: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.LoadingMoreUserProfilePosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-posts", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSingleUserProfileListingsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfileListings(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/user-property-listing", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchSingleUserProfileReviewsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfileReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-users-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchSingleUserProfileGivenReviewsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserProfileGivenReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-user-given-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchSingleUserSavedPropertiesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserSavedProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-users-saved-properties", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSingleUserSavedPostThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserSavedPost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-users-saved-post", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchSingleUserSavedServiceThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserSavedService(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-users-saved-services", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSingleUserSavedOFBThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserSavedOFB(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-users-saved-ofb", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSingleUserAnalyticsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleUserAnalytics(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-analytics", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileImagesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileImages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-profile-images", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileAboutDetailsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileAboutDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-about-details", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileSubscriptionThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileSubscription(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-subscription", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileFollowerAndFollowingThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileFollowerAndFollowing(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-followers-and-followings", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileServiceOfferedThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileServiceOffered(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-service-offered", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileExpertiseThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileExpertise(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-expertise", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileBrokerageThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileBrokerage(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-brokerage", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileWhychooseThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileWhychoose(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-whyChoose-us", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileHiringThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileHiring(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-vacancies", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileHonorsAndAwardsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileHonorsAndAwards(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-honors-and-awards", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileFaqsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileFaqs(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-faqs", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileLanguagesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileLanguages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-languages", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileLicencesAndCertificatesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileLicencesAndCertificates(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-licences-and--certificates", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileNoOfEmployeesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileNoOfEmployees(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-no-of-employees", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileListingPropertyThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileListingProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-listings-property", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileReviewsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-business-profile-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileListingServiceThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileListingService(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-listings-services", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileListingOFBThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileListingOFB(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-listings-ofb", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileLeadsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileLeads(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-leads", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileSavedPropertyThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileSavedProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-saved-properties", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileSavedServiceThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileSavedService(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-saved-services", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileSavedOFBThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileSavedOFB(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-saved-ofb", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileSavedPostThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileSavedPost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-saved-posts", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileAnalyticsPropertyThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileAnalyticsProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-properties-analytics", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileAnalyticsServiceThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileAnalyticsService(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-service-analytics", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileAnalyticsPostThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileAnalyticsPost(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-post-analytics", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileAnalyticsOFBsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileAnalyticsOFBs(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-ofbs-analytics", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchBusinessProfileAdminsDetailsAndEmployeeRequestThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileAdminsDetailsAndEmployeeRequest(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-admins-details", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchPropertyDataReviewsPageThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchPropertyDataReviewsPageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + slug + "/fetch-property-data-reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ slug, sessionId }),
					})
				).json(),
			),
		);
	};
export const fetchPropertyUserDataReviewsPageThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchPropertyUserDataReviewsPageData(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-user-data-reviews",
						{
							method: "POST",
							headers: {
								"Content-Type": "text/plain",
							},
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};
export const fetchServiceDataReviewsPageThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchServiceDataReviewsPageData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-data-reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};
export const fetchServiceUserDataReviewsPageThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchServiceUserDataReviewsPageData(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "services/" + payload.slug + "/fetch-service-user-data-reviews",
						{
							method: "POST",
							headers: {
								"Content-Type": "text/plain",
							},
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};
export const fetchSingleServiceImagesThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceImages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-images", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceDetailsThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-details", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceOverviewThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceOverview(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-overview", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceSpecificationsThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceSpecifications(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-specifications", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceProviderThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceProvider(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-provider", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceAreasOfOperationThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceAreasOfOperation(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-areas-of-operation", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceMapViewThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceMapView(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-map-view", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceReviewsThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceCreatorDetailsThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceCreatorDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-service-creator-details", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceTopServicesThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceTopServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-top-services", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const fetchSingleServiceTrendingServicesThunk =
	({ slug, sessionId }: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSingleServiceTrendingServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/" + slug + "/fetch-trending-services", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({ sessionId }),
					})
				).json(),
			),
		);
	};

export const setSingleServiceCategoryThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceCategory(payload));
	};

export const setCreateNewServiceCategoryThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceCategory(payload));
	};

export const setSingleServiceTypeThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceType(payload));
	};

export const setCreateNewServiceTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceType(payload));
	};

export const setSingleServiceSubtypeThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceSubtype(payload));
	};

export const setCreateNewServiceSubTypeThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceSubType(payload));
	};

export const setSingleServiceOtherSubtypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceOtherSubtype(payload));
	};

export const setCreateNewServiceOtherSubTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceOtherSubType(payload));
	};

export const setSingleServiceTagsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceTags(payload));
	};

export const setSingleServiceShowPriceThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceShowPrice(payload));
	};

export const setCreateNewServiceShowPriceThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceShowPrice(payload));
	};

export const setSingleServiceMinPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceMinPrice(payload));
	};

export const setCreateNewServiceMinPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceMinPrice(payload));
	};

export const setSingleServiceMaxPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceMaxPrice(payload));
	};

export const setCreateNewServiceMaxPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceMaxPrice(payload));
	};

export const setCreateNewServiceUploadImagesThunk =
	(
		payload: Array<{
			file_id: ObjectId | string;
			mime_type: string;
			category: string;
			caption: string;
			priority: number;
			stage: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceUploadImages(payload));
	};

export const setCreateNewServiceDefaultLocationThunk =
	(payload: { location_coordinates: Array<number>; address: string }): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceDefaultLocation(payload));
	};

export const setSingleServiceOverviewThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceOverview(payload));
	};

export const setSingleServiceSpecificationThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceSpecification(payload));
	};

export const setCreateNewServiceProviderNameThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceProviderName(payload));
	};

export const setSingleServiceProviderNameThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceProviderName(payload));
	};

export const setSingleServiceProviderEstablishedYearThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceProviderEstablishedYear(payload));
	};

export const setSingleServiceProviderDescriptionThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceProviderDescription(payload));
	};

export const setSingleServiceAreasOfOperationThunk =
	(
		payload:
			| Array<{
					title: string;
					pincode: string;
					location_coordinates: Array<number>;
			  }>
			| [],
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceAreasOfOperation(payload));
	};

export const setSingleServiceLocationTitleThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceLocationTitle(payload));
	};

export const setSingleServiceLocationPincodeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceLocationPincode(payload));
	};

export const setSingleServiceLocationCoordinatesThunk =
	(payload: Array<number>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSingleServiceLocationCoordinates(payload));
	};

export const setSectionToEditThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSectionToEdit(payload));
	};

export const setDataBeforeEditThunk =
	(payload: any): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setDataBeforeEdit(payload));
	};

export const fetchSinglePropertyDetailsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyDetails(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-details", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchDevelopersThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchDevelopers(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-developers", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchNearbyPropertiesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchNearbyProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-nearby-properties", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyConfigAndAreaThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyConfigAndArea(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-config-and-area",
						{
							method: "POST",
							headers: {
								"Content-Type": "text/plain",
							},
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const setSinglePropertyTransactionTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyTransactionType(payload));
	};

export const setCreateNewPropertyTransactionTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyTransactionType(payload));
	};

export const setCreateNewPropertyCurrentPageThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyCurrentPage(payload));
	};

export const setCreateNewServiceCurrentPageThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewServiceCurrentPage(payload));
	};

export const setSinglePropertyCategoryTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyCategoryType(payload));
	};

export const setCreateNewPropertyCategoryTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyCategoryType(payload));
	};

export const setSinglePropertyTypeThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyType(payload));
	};

export const setCreateNewPropertyTypeThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyType(payload));
	};

export const setSinglePropertySubTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertySubType(payload));
	};

export const setCreateNewPropertySubTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertySubType(payload));
	};

export const setSinglePropertyMinPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMinPrice(payload));
	};

export const setCreateNewPropertyMinPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMinPrice(payload));
	};

export const setSinglePropertyMaxPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxPrice(payload));
	};

export const setCreateNewPropertyMaxPriceThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxPrice(payload));
	};

export const setSinglePropertySecurityDepositThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertySecurityDeposit(payload));
	};

export const setCreateNewPropertySecurityDepositThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertySecurityDeposit(payload));
	};

export const setSinglePropertyAvailabiltyThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyAvailabilty(payload));
	};

export const setCreateNewPropertyAvailabiltyThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyAvailabilty(payload));
	};

export const setSinglePropertyPossessionDateThunk =
	(payload: string | Date): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyPossessionDate(payload));
	};

export const setCreateNewePropertyPossessionDateThunk =
	(payload: string | Date): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewePropertyPossessionDate(payload));
	};

export const setSinglePropertyTagsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyTags(payload));
	};

export const setSinglePropertyUnitFacingThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyUnitFacing(payload));
	};

export const setCreateNewPropertyUnitFacingThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyUnitFacing(payload));
	};

export const setSinglePropertyLandFacingThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyLandFacing(payload));
	};

export const setCreateNewPropertyLandFacingThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyLandFacing(payload));
	};

export const setSinglePropertyAgeThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyAge(payload));
	};

export const setCreateNewPropertyAgeThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyAge(payload));
	};

export const setSinglePropertyFurnishingThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyFurnishing(payload));
	};

export const setCreateNewPropertyFurnishingThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyFurnishing(payload));
	};

export const setSinglePropertyPlotDimensionsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyPlotDimensions(payload));
	};

export const setCreateNewPropertyPlotDimensionsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyPlotDimensions(payload));
	};

export const setCreateNewPropertyOverviewThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyOverview(payload));
	};

export const setSinglePropertyOtherPlotDimensionsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyOtherPlotDimensions(payload));
	};

export const setSinglePropertyLandAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyLandArea(payload));
	};

export const setCreateNewPropertyLandAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyLandArea(payload));
	};

export const setCreateNewPropertyLandAreaUnitThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyLandAreaUnit(payload));
	};

export const setSinglePropertyMinSuperBuiltUpAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMinSuperBuiltUpArea(payload));
	};

export const setCreateNewPropertyMinSuperBuiltUpAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNePropertyMinSuperBuiltUpArea(payload));
	};

export const setSinglePropertyMaxSuperBuiltUpAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxSuperBuiltUpArea(payload));
	};

export const setCreateNewPropertyMaxSuperBuiltUpAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxSuperBuiltUpArea(payload));
	};

export const setCreateNewPropertySuperBuiltUpAreaUnitThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertySuperBuiltUpAreaUnit(payload));
	};

export const setSinglePropertyMinCarpetAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMinCarpetArea(payload));
	};

export const setCreateNewPropertyMinCarpetAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMinCarpetArea(payload));
	};

export const setSinglePropertyMaxCarpetAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxCarpetArea(payload));
	};

export const setCreateNewPropertyMaxCarpetAreaThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxCarpetArea(payload));
	};

export const setSinglePropertyMinBedroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMinBedrooms(payload));
	};

export const setCreateNewPropertyMinBedroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMinBedrooms(payload));
	};

export const setSinglePropertyMaxBedroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxBedrooms(payload));
	};

export const setCreateNewPropertyMaxBedroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxBedrooms(payload));
	};

export const setSinglePropertyMinBathroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMinBathrooms(payload));
	};

export const setCreateNewPropertyMinBathroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMinBathrooms(payload));
	};

export const setSinglePropertyMaxBathroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxBathrooms(payload));
	};

export const setCreateNewPropertyMaxBathroomsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxBathrooms(payload));
	};

export const setSinglePropertyMinBalconiesThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMinBalconies(payload));
	};

export const setCreateNePropertyMinBalconiesThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNePropertyMinBalconies(payload));
	};

export const setSinglePropertyMaxBalconiesThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxBalconies(payload));
	};

export const setCreateNewPropertyMaxBalconiesThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxBalconies(payload));
	};

export const setSinglePropertyMaxFloorsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyMaxFloors(payload));
	};

export const setCreateNewPropertyMaxFloorsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyMaxFloors(payload));
	};

export const setSinglePropertyFloorNoThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyFloorNo(payload));
	};

export const setCreateNewPropertyFloorNoThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyFloorNo(payload));
	};

export const setSinglePropertyNoOfUnitsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyNoOfUnits(payload));
	};

export const setCreateNewPropertyNoOfUnitsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyNoOfUnits(payload));
	};

export const setSinglePropertyNoOfSeatsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyNoOfSeats(payload));
	};

export const setCreateNewPropertyNoOfSeatsThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyNoOfSeats(payload));
	};

export const setCreatePropertySelectedImagesUrlThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreatePropertySelectedImagesUrl(payload));
	};

export const setCreateNewPropertyUploadImagesThunk =
	(
		payload: Array<{
			file_id: ObjectId | string;
			mime_type: string;
			category: string;
			caption: string;
			priority: number;
			stage: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyUploadImages(payload));
	};

export const setCreateNewPropertyDefaultLocationThunk =
	(payload: { location_coordinates: Array<number>; address: string }): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setCreateNewPropertyDefaultLocation(payload));
	};

export const fetchAboutSinglePropertyThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAboutSingleProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-about-property", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyCreatorThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyCreator(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-single-property-creators-details",
						{
							method: "POST",
							headers: {
								"Content-Type": "text/plain",
							},
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const fetchSinglePropertyAboutLocationThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyAboutLocation(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-about-location",
						{
							method: "POST",
							headers: {
								"Content-Type": "text/plain",
							},
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const fetchSinglePropertyAmenitiesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyAmenities(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-amenities", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setSinglePropertyAmenitiesThunk =
	(
		payload: Array<{
			id: string;
			label: string;
			icon: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyAmenities(payload));
	};

export const setSinglePropertyAboutThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyAbout(payload));
	};

export const setSinglePropertyDeveloperNameThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyDeveloperName(payload));
	};

export const setSinglePropertyEstablishedYearThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyEstablishedYear(payload));
	};

export const setSinglePropertyDescriptionThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyDescription(payload));
	};

export const setSinglePropertyAboutLocationThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyAboutLocation(payload));
	};

export const setSinglePropertyVicinityThunk =
	(
		payload: Array<{
			category: string;
			values: Array<{
				title: string;
				distance: number;
			}>;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyVicinity(payload));
	};

export const fetchSinglePropertyDeveloperThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyDeveloper(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-developer", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyImagesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyImages(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-images", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyLocationCoordinatesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyLocationCoordinates(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-location-coordinates",
						{
							method: "POST",
							headers: {
								"Content-Type": "text/plain",
							},
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const fetchSinglePropertyOverviewThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyOverview(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-overview", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setSinglePropertyOverviewThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setSinglePropertyOverview(payload));
	};

export const fetchSinglePropertyReviewsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyVicinityThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyVicinity(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-property-vicinity", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyTrendingPropertiesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyTrendingProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-trending-properties", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyHotPropertiesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyHotProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-hot-properties", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchSinglePropertyNeighbourhoodReviewsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSinglePropertyNeighbourhoodReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/" + payload.slug + "/fetch-neighbourhood-reviews", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchAccountSettingsUserDataThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsUserData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-user-data", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchAccountSettingsUserCashtransactionsThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsUserCashtransactions(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-user-cash-transactions", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchAccountSettingsUserBeegruPointstransactionsThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsUserBeegruPointstransactions(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-user-beegru-points-transactions", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchAccountSettingsSubscriptionsPlansThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsSubscriptionsPlans(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-subscriptions-plans", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchAccountSettingsSubscriptionsThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsSubscriptions(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-subscriptions", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const fetchAccountSettingsFilesCountThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsFilesCount(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-file-counts", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setBusinessProfileAboutShortDescriptionThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutShortDescription(payload));
	};
export const setBusinessProfileAboutLongDescriptionThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutLongDescription(payload));
	};
export const setBusinessProfilePhoneCountryCodeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfilePhoneCountryCode(payload));
	};
export const setBusinessProfilePhoneNumberThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfilePhoneNumber(payload));
	};
export const setBusinessProfileAboutYearEstablishedThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutYearEstablished(payload));
	};
export const setBusinessProfileAboutOfficeAddressThunk =
	(
		payload: Array<{
			line1: string;
			line2: string;
			city: string;
			state: string;
			country: string;
			pincode: string;
			is_hq: boolean;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutOfficeAddress(payload));
	};
export const setBusinessProfileAboutWebsiteUrlThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutWebsiteUrl(payload));
	};
export const setBusinessProfileAboutEducationThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutEducation(payload));
	};
export const setBusinessProfileAboutOrganizationThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutOrganization(payload));
	};
export const setBusinessProfileAboutDesignationThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutDesignation(payload));
	};
export const setBusinessProfileServicesOfferedTagsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileServicesOfferedTags(payload));
	};
export const setBusinessProfileExpertiseThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileExpertise(payload));
	};
export const setBusinessProfileWhyChooseUsThunk =
	(payload: Array<string>): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileWhyChooseUs(payload));
	};
export const setBusinessProfileBrokerageThunk =
	(
		payload: Array<{
			brokerage_for: string;
			brokerage_percentage: number;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileBrokerage(payload));
	};
export const setBusinessProfileVacanciesThunk =
	(
		payload: Array<{
			designation: string;
			experience: string;
			salary: string;
			number_of_vacancies: number;
			skills_required: Array<string> | [];
			job_description: string;
			job_qualifications: Array<string> | [];
			job_location: string;
			job_timings: string;
			job_responsibilities: Array<string> | [];
			active: boolean;
			updated_at: Date | string;
			created_at: Date | string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileVacancies(payload));
	};
export const setBusinessProfileHonorsAndAwardsThunk =
	(
		payload: Array<{
			title: string;
			description: string;
			issued_by: string;
			issued_at: Date | string;
			media_url: string;
			mime_type: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileHonorsAndAwards(payload));
	};
export const setBusinessProfileLicencesAndCertificatesThunk =
	(
		payload: Array<{
			title: string;
			description: string;
			issued_by: string;
			issued_at: Date | string;
			media_url: string;
			mime_type: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileLicencesAndCertificates(payload));
	};
export const setBusinessProfileNoOfEmployeesThunk =
	(payload: number): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileNoOfEmployees(payload));
	};
export const setBusinessProfileFAQThunk =
	(
		payload: Array<{
			question: string;
			answer: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileFAQ(payload));
	};
export const setBusinessProfileLanguagesThunk =
	(
		payload: Array<{
			language: string;
			proficiency: string;
		}>,
	): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileLanguages(payload));
	};

export const setBusinessProfileEmailThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileEmail(payload));
	};
export const setBusinessProfileAboutIndustryTypeThunk =
	(payload: string): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileAboutIndustryType(payload));
	};
export const deleteReviewThunk =
	(payload: { entity_id: string | ObjectId; source: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.deleteReview({
				source: payload.source,
				response: await (
					await fetch(process.env.PRODUCTION_API_URL + "reviews/delete-review", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			}),
		);
	};

export const CopyListPropertyThunk =
	(payload: {
		property_id: string | ObjectId;
		slug: string;
		creator_is_business_profile: boolean;
		creator_user_id: string | ObjectId;
		creator_business_profile_id: string | ObjectId;
		profile_context_is_business_profile: boolean;
		profile_context_user_id: string | ObjectId;
		profile_context_business_profile_id: string | ObjectId;
		linked: boolean;
		linked_to: string | ObjectId;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.createNewProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/copy-listing-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AllowcopyPropertyThunk =
	(payload: { property_id: string | ObjectId; allow_copy: boolean }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AllowcopyProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/allow-copy-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const ShowContactDetailsPropertyThunk =
	(payload: { property_id: string | ObjectId; show_contact_details: boolean }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.ShowContactDetailsProperty(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/edit-property/show-contact-details-property", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const ShowContactDetailsServiceThunk =
	(payload: { service_id: string | ObjectId; show_contact_details: boolean }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.ShowContactDetailsService(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "services/edit-service/show-contact-details-service", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const AddingBeegruPointsForSharePropertyThunk =
	(payload: { user_id: string | ObjectId; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.addingbeegrupointsshareproperty(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "properties/edit-property/adding-beegru-points-for-share-property",
						{
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const AddingBeegruPointsForShareServiceThunk =
	(payload: { user_id: string | ObjectId; url: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.AddingBeegruPointsForShareService(
				await (
					await fetch(
						process.env.PRODUCTION_API_URL + "services/edit-services/adding-beegru-points-for-share-service",
						{
							method: "POST",
							cache: "no-store",
							body: JSON.stringify(payload),
						},
					)
				).json(),
			),
		);
	};

export const fetchBusinessProfilePostsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfilePosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-business-profiles-posts", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const LoadingMoreBusinessProfilePostsThunk =
	(payload: { business_id: string | ObjectId; sessionId: string; pageNumber: string; pageSize: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.LoadingMoreBusinessProfilePosts(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-posts", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setPropertySnackbarThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setPropertySnackbar(payload));
	};

export const setServiceSnackbarThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setServiceSnackbar(payload));
	};

export const setAccountSettingsSnackbarThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setAccountSettingSnackbar(payload));
	};

export const searchUserPropertiesThunk =
	(payload: { search_term: string; sessionId: string; user_id: string | ObjectId; skip: number }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchUserProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/property-search-results", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const setHomeLoadingThunk =
	(payload: StateInterface["home_loading"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setHomeLoading(payload));
	};
export const setUserLoadingThunk =
	(payload: StateInterface["user_profile_loading"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setUserLoading(payload));
	};
export const setHomePagePropertiesLoadingThunk =
	(loading: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setHomePagePropertiesLoading(loading));
	};
export const searchBusinessProfilePropertiesThunk =
	(payload: {
		search_term: string;
		sessionId: string;
		business_profile_id: string | ObjectId;
		business_profile_slug: string;
		skip: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchBusinessProfileProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/property-search-results", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const searchBusinessProfileOfbPropertiesThunk =
	(payload: {
		search_term: string;
		sessionId: string;
		business_profile_id: string | ObjectId;
		business_profile_slug: string;
		skip: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchBusinessProfileOfbProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/ofb-property-search-results", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const searchOfbPropertyListingsThunk =
	(payload: {
		search_term: string;
		sessionId: string;
		business_profile_id: string | ObjectId;
		business_profile_slug: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchOfbPropertyListings(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/search-ofb-property-listings", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const searchBusinessProfileServicesThunk =
	(payload: {
		search_term: string;
		sessionId: string;
		business_profile_id: string | ObjectId;
		business_profile_slug: string;
		skip: number;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchBusinessProfileServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/services-search-results", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const searchServiceListingsThunk =
	(payload: {
		search_term: string;
		sessionId: string;
		business_profile_id: string | ObjectId;
		business_profile_slug: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.searchServiceListings(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/search-service-listings", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setBusinessProfileSnackbarThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setBusinessProfileSnackbar(payload));
	};
export const LoadingMoreUserGivenReviewsThunk =
	(payload: { user_id: string | ObjectId; sessionId: string; pageNumber: string; pageSize: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.LoadingMoreUserGivenReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-given-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const LoadingMoreUserReviewsThunk =
	(payload: { user_id: string | ObjectId; sessionId: string; pageNumber: string; pageSize: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.LoadingMoreUserReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/load-more-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfileGivenReviewsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfileGivenReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-business-profile-given-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const LoadingMoreBusinessProfileGivenReviewsThunk =
	(payload: {
		business_profile_id: string | ObjectId;
		sessionId: string;
		pageNumber: string;
		pageSize: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.LoadingMoreBusinessProfileGivenReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-business-profile-given-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const LoadingMoreBusinessProfileReviewsThunk =
	(payload: {
		business_profile_id: string | ObjectId;
		sessionId: string;
		pageNumber: string;
		pageSize: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.LoadingMoreBusinessProfileReviews(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/load-more-reviews", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setTransactionTypeThunk =
	(payload: StateInterface["transaction"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setTransactionType(payload));
	};

export const setOnBoardingDetailsThunk =
	(payload: StateInterface["on_boarding_details"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setOnBoardingDetails(payload));
	};

export const fetchOnBoardingBusinessTitleThunk = (): AppThunk => async (dispatch) => {
	dispatch(
		globalSlice.actions.fetchOnBoardingBusinessTitle(
			await (
				await fetch(process.env.PRODUCTION_API_URL + "on-boarding/fetch-business-title", {
					method: "POST",
					cache: "no-store",
					body: "",
				})
			).json(),
		),
	);
};

export const setUserProfileSnackbarThunk =
	(payload: boolean): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.setUserProfileSnackbar(payload));
	};

export const NewEmployeeRquestOnBoardingThunk =
	(payload: {
		employee_is_business_profile: boolean;
		business_profile_id: ObjectId | string;
		editor_user_id: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.NewEmployeeRquestOnBoarding(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "on-boarding/new-employee-request", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchUserProfileLeadsThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchUserProfileLeads(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/fetch-leads", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setBottomBuyBeegruPointsThunk =
	(payload: StateInterface["bottom_subscribe_buy_beegru_points"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setBottomBuyBeegruPoints(payload));
	};

export const setDefaultProfileContextThunk =
	(payload: StateInterface["profile_context"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setDefaultProfileContext(payload));
	};

export const updateReduxSessionThunk =
	(payload: { session: StateInterface["redux_session"] }): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.updateReduxSession(payload));
	};

export const FetchMoreBusinessProfilesThunk =
	(payload: { user_id: ObjectId | string; pages: Array<{ page_id: ObjectId | string }> }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.FetchMoreBusinessProfiles(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "fetch-more-business-profiles", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfilePublishedPropertiesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfilePublishedProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-published-properties", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updatedPublishedPropertiesThunk =
	(payload: {
		selectedProperties: Array<string>;
		filterProperties: Array<any>;
		action: string;
		sessionId: any;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updatedPublishedProperties(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-published-properties", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchBusinessProfilePublishedServicesThunk =
	(payload: { slug: string; sessionId: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchBusinessProfilePublishedServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/fetch-published-services", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updatedPublishedServicesThunk =
	(payload: {
		selectedProperties: Array<string>;
		filterProperties: Array<any>;
		action: string;
		sessionId: any;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.updatedPublishedServices(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/update-published-services", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchAccountSettingsRewardTransactionThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchAccountSettingsRewardtransactions(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-rewards-history", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchOneTimeRewardsThunk =
	(payload: { slug: string; skip: number }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchOneTimeRewards(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-rewards-one-time", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const fetchRecurringRewardsThunk =
	(payload: { slug: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchRecurringRewards(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "account-settings/fetch-recurring-rewards", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const updateSearchFilterActiveOptionsThunk =
	({
		searchFilterId,
		newOptions,
	}: {
		searchFilterId: SearchFilterId;
		newOptions: Array<SearchFilterOption>;
	}): AppThunk =>
	(dispatch: Function) => {
		dispatch(globalSlice.actions.updateSearchFilterActiveOptions({ searchFilterId, newOptions }));
	};

export const updateSearchFilterSelectedOptionsThunk =
	({
		searchFilterId,
		newOptions,
	}: {
		searchFilterId: SearchFilterId;
		newOptions: Array<SearchFilterOption>;
	}): AppThunk =>
	(dispatch: Function) => {
		dispatch(globalSlice.actions.updateSearchFilterSelectedOptions({ searchFilterId, newOptions }));
	};

export const updateSearchFilterVisibilityThunk =
	({ searchFilterId, isVisible }: { searchFilterId: SearchFilterId; isVisible: boolean }): AppThunk =>
	(dispatch: Function) => {
		dispatch(globalSlice.actions.updateSearchFilterVisibility({ searchFilterId, isVisible }));
	};

export const updateSearchFilterAppliedThunk =
	({ searchFilterId, isApplied }: { searchFilterId: SearchFilterId; isApplied: boolean }): AppThunk =>
	(dispatch: Function) => {
		dispatch(globalSlice.actions.updateSearchFilterApplied({ searchFilterId, isApplied }));
	};

export const updateSearchFilterIsAccordionOpenThunk =
	({ searchFilterId, isAccordionOpen }: { searchFilterId: SearchFilterId; isAccordionOpen: boolean }): AppThunk =>
	(dispatch: Function) => {
		dispatch(globalSlice.actions.updateSearchFilterIsAccordionOpen({ searchFilterId, isAccordionOpen }));
	};

export const setSearchFilterMetricesThunk =
	({
		boosted,
		non_boosted,
		filter_hash,
		no_of_boosted_results,
		no_of_non_boosted_results,
		total_no_of_results,
	}: {
		boosted: Array<number>;
		non_boosted: Array<number>;
		filter_hash: string;
		no_of_boosted_results: number;
		no_of_non_boosted_results: number;
		total_no_of_results: number;
	}): AppThunk =>
	(dispatch: Function) => {
		dispatch(
			globalSlice.actions.setSearchFilterMetrices({
				boosted,
				non_boosted,
				filter_hash,
				no_of_boosted_results,
				no_of_non_boosted_results,
				total_no_of_results,
			}),
		);
	};

export const CreateNewViewThunk =
	(payload: {
		entity_creator_user_id: string | ObjectId;
		viewed_entity_type: string;
		viewed_entity_id: string | ObjectId;
		viewer_is_logged_in: boolean;
		viewer_is_business_profile: boolean;
		viewer_user_id: string | ObjectId;
		viewer_business_profile_id: string | ObjectId;
		viewer_session_id: string | ObjectId;
		viewer_ip_address: string | null;
		viewer_user_agent: string | null;
		source: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreateNewView(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "analytics/create-new-view", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const setSearchAnimationDetailsThunk =
	(payload: StateInterface["search_animation_settings"]): AppThunk =>
	async (dispatch) => {
		dispatch(globalSlice.actions.setSearchAnimationDetails(payload));
	};

export const fetchSearchThunk =
	({
		db_queries,
		page_number,
		page_size,
		search_filter_metrices,
		session_id,
		user_id,
		profile_context,
	}: {
		db_queries: output_type_of_database_query;
		page_number: number;
		page_size: string | number | undefined;
		search_filter_metrices: SearchFilterMetrices;
		session_id: string | ObjectId;
		user_id: string | ObjectId;
		profile_context: profile_contextInterface | null;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchSearchData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "search-test-api", {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({
							db_queries,
							page_number,
							page_size,
							search_filter_metrices,
							session_id,
							user_id,
							profile_context,
						}),
					})
				).json(),
			),
		);
	};
export const fetchPropertyCardThunk =
	({ _id }: { _id: any }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.fetchPropertyCardData(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "properties/cards/" + _id, {
						method: "POST",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify({
							_id,
						}),
					})
				).json(),
			),
		);
	};

// PropertyBoost
type PropertyBoostMobileStep = {
	step: number;
};
export const SetPropertyBoostMobileStepStateThunk =
	(payload: PropertyBoostMobileStep): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostMobileStepState(payload));
	};

type PropertyBoostSnack = {
	snack_state: boolean;
	snack_message: string;
	snack_severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
};
export const SetPropertyBoostSnackStateThunk =
	(payload: PropertyBoostSnack): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostSnackState(payload));
	};

type PropertyBoostModal = { state?: boolean; id?: string | ObjectId };
export const SetPropertyBoostModalStateThunk =
	(payload: PropertyBoostModal): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostModalState(payload));
	};

export const SetPropertyBoostedByStateThunk =
	(payload: BoostInterface["boosted_by"]): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostedByState(payload));
	};

type PropertyBoostData = { slug: string | ObjectId };
export const SetPropertyBoostDataStateThunk =
	(payload: PropertyBoostData): AppThunk =>
	async (dispatch) => {
		console.log("called");
		dispatch(
			globalSlice.actions.SetPropertyBoostDataState(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "boost-property/fetch-property-detail", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

type PropertyBoostDetail = {
	snack?: boolean;
	weeks_count?: number;
	start_date?: string;
	temp_start_date?: string;
};
export const SetPropertyBoostDetailStateThunk =
	({ snack = true, ...payload }: PropertyBoostDetail): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostDetailState({ ...payload, snack }));
	};

type PropertyBoostLocation = {
	snack?: boolean;
	location?: BoostLocation[];
	tempLocation?: BoostLocation[];
	selected?: BoostLocation | null;
};
export const SetPropertyBoostLocationStateThunk =
	({ snack = true, ...payload }: PropertyBoostLocation): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostLocationState({ ...payload, snack }));
	};

type PropertyBoostPoints = {
	snack?: boolean;
	points?: number;
	budget?: number;
	availablePoints?: number;
	checkAvailablePoints?: number;
};
export const SetPropertyBoostPointsStateThunk =
	({ snack = true, ...payload }: PropertyBoostPoints): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyBoostPointsState({ ...payload, snack }));
	};

type PropertyLoading = {
	submitLoading?: boolean;
};
export const SetPropertyLoadingStateThunk =
	(payload: PropertyLoading): AppThunk =>
	(dispatch) => {
		dispatch(globalSlice.actions.SetPropertyLoadingState(payload));
	};

type CreatePropertyBoost = {
	boost: BoostInterface;
};
export const CreatePropertyBoostStateThunk =
	(payload: CreatePropertyBoost): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CreatePropertyBoostState(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "boost-property/create-property-boost", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload.boost),
					})
				).json(),
			),
		);
	};

type CancelPropertyBoost = {
	boost: PropertyBoostInterface;
};
export const CancelPropertyBoostStateThunk =
	(payload: CancelPropertyBoost): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.CancelPropertyBoostState(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "boost-property/create-property-boost", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload.boost),
					})
				).json(),
			),
		);
		dispatch(
			globalSlice.actions.CancelPropertyBoostState(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "boost-property/cancel-property-boost", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload.boost),
					})
				).json(),
			),
		);
	};

export const UpdatePropertyTitleThunk =
	(payload: {
		phoneNumber: string;
		countryCode: string;
		property_id: string | ObjectId;
		editor_is_business_profile: boolean;
		editor_user_id: string | ObjectId;
		editor_business_profile_id: string | ObjectId;
		property_title: string;
		slug: string;
	}): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdatePropertyTitle(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "/properties/edit-property/update-property-title", {
						method: "POST",
						cache: "no-store",
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateBusinessProfileLeadsStatusThunk =
	(payload: { status: any; leadsId: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessProfileLeadsStatus(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/leads-status", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const UpdateBusinessProfileLeadsNotesThunk =
	(payload: { leadsId: string | ObjectId; notes: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateBusinessProfileLeadsNotes(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "business-profiles/leads-notes", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};

export const UpdateUserProfileLeadsStatusThunk =
	(payload: { status: any; leadsId: string | ObjectId }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateUserProfileLeadsStatus(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/leads-status", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};
export const UpdateUserProfileLeadsNotesThunk =
	(payload: { leadsId: string | ObjectId; notes: string }): AppThunk =>
	async (dispatch) => {
		dispatch(
			globalSlice.actions.UpdateUserProfileLeadsNotes(
				await (
					await fetch(process.env.PRODUCTION_API_URL + "user-profile/leads-notes", {
						method: "POST",
						cache: "no-store",
						headers: {
							"Content-Type": "text/plain",
						},
						body: JSON.stringify(payload),
					})
				).json(),
			),
		);
	};